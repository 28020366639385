import { useContext, useState, useEffect } from 'react';
import { Context } from 'context/Context';
import styles from 'styles/TotalCart.module.scss';
import { useRouter } from 'next/router';
import Navbar from 'componentes/Navbar';
import CartBar from 'componentes/CartBar';
import LineCart from 'componentes/LineCart';
import { currencies } from 'services/helpers'
import formatNumber from 'services/formatNumber';
import { ContainerModal, Alert, TextAlert, AlertContainer } from './styled';
import { cartLine } from 'services/cart';
import getTotal from 'services/getTotal';
import Image from 'next/image';
import { trackPreviewCart } from './track';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import message from 'componentes/GlobalMessages/messages'
import { dateSelector } from 'services/featureflags';

const ModalPreviewOrder = ({ products, closeModal, actionContinue, isMenuView, menuName }) => {
  const intl = useIntl();
  const [showAlert, setShowAlert] = useState(true);
  const router = useRouter();

  const { currentInfoCountry, sucursal, pais, dataShop, deliveryPointSelector } = useContext(Context);

  const textTotalDiscount = intl.formatMessage(message.labelSaveId);

  const dateSelectorFeature = dateSelector(dataShop?.country_id);

  const total = getTotal(products.active_products);

  useEffect(() => {
    if (total === 0 && sucursal) {
      router.push(`/${sucursal}`);
    }
  }, [total]);

  useEffect(() => {
    trackPreviewCart(currentInfoCountry?.currency_code ?? null, total, products);
  }, []);

  const textCart = intl.formatMessage(messages.labelAddToCartId);
  const changeView = true;

  if(products) return (
    <ContainerModal isMenuView={isMenuView} showDateSelector={dateSelectorFeature} deliveryPointSelector={deliveryPointSelector}>
      <Navbar isMenuView={isMenuView} title={isMenuView ? menuName : intl.formatMessage(messages.titleRepeatOrder)} action={closeModal}/>
      {
        showAlert && !isMenuView &&
        <AlertContainer>
          <Alert>
            <TextAlert>{intl.formatMessage(messages.textDisclaimerPrices)}</TextAlert>
            <Image src='/imgs/closeTab.svg' width={16} height={16} onClick={() => setShowAlert(false)}/>
          </Alert>
        </AlertContainer>
      }
      {
        products.active_products.map((product, index) => (
          <LineCart line={cartLine(product.product, product.product.category_name, product.quantity)} key={index} textTotalDiscount={textTotalDiscount} preview />
        ))
      }
      {
        products.inactive_products.map((product, index) => (
          <LineCart line={cartLine(product.product, product.product.category_name, product.quantity)} key={index} textTotalDiscount={textTotalDiscount} preview notAvailable={true}/>
        ))
      }

      <div className={styles.containerTotalPrice}>
        <h2 className={styles.totalText}>
        <FormattedMessage
         id='labelTotalCart'
         defaultMessage='Total'                
        />
        </h2>
        <h1 className={styles.totalNumber}>{currencies[pais]}<span style={{width: '2px'}} />{formatNumber(total, pais)}</h1>
      </div>

      <CartBar
        text={textCart}
        sucursal={sucursal}
        handleClick={actionContinue}
        changeView={changeView}
        total={total}
      />
    </ContainerModal>
  );
  return<></>
}

export default ModalPreviewOrder;