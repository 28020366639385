import getproduct from "./getproduct";
import { finalPrice } from "./producthelpers";

const repeatOrder = async (lines, tierId) => {
    const active_products = []
    const inactive_products = []
    const promises = lines.map(async line => {
        const product = await getproduct(line.product_id, tierId);
        if (product.is_active && product['master-product'].erp_active) {
            active_products.push({ product, quantity: (parseInt(line.quantity.value) <= product.max_units_per_order || product.max_units_per_order === 0) ? parseInt(line.quantity.value) : product.max_units_per_order, previous_price: (parseFloat(line.total.value) / parseInt(line.quantity.value)), price_difference: finalPrice(product) - (parseFloat(line.total.value) / parseInt(line.quantity.value))});
            return
        } 
        if(product?.is_active === false || product['master-product']?.erp_active === false && !product?.message) {
            inactive_products.push({product, quantity: parseInt(line.quantity.value)});
            return
        } 
        if(product.message) {
            inactive_products.push({product: {...line, name: line.product_name, ['master-product']: {
                image: '/defaultproducto.png'
            }}, quantity: parseInt(line.quantity.value)});
            return
        }
    });
    await Promise.all(promises)
    return {active_products, inactive_products}
}

export default repeatOrder;