import styled from  'styled-components'

export const ContainerModal = styled.div`
display: flex;
flex-direction: column;
background-color: white;
min-height: 1000px;
width: 100vw;
position: absolute;
padding: 25px 0 40vw 0;
top: ${props => props.isMenuView ? '0' : '60px'};
z-index: 10;
max-width: 490px;
`
export const AlertContainer = styled.div`
padding: 0 20px;
width: 100%;
margin-bottom: 20px;
`
export const Alert = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-start;
background-color: #E6E9E7;
border-radius: 8px;
padding: 12px 16px;
`
export const TextAlert = styled.div`
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
display: flex;
align-items: flex-end;
color: #011D0E;
width: 100%;
`
