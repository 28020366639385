import { track } from 'services/gtm';

const finalProducts = (products) => {
    const finalProducts = []
    const activePromises = products.active_products.map(async product => {
        finalProducts.push({ ...product, product: {...product.product, is_active: true}});
    })
    const inactivePromises = products.inactive_products.map(async product => {
      finalProducts.push({ ...product, product: {...product.product, is_active: false}});
    })
    Promise.all([...activePromises, ...inactivePromises])
    return finalProducts
}

export function trackPreviewCart(currency, total, products) {
    const allproducts = finalProducts(products)
    track('preview_repeated_cart', {
      ecommerce: {
        currency: currency,
        value: total,
        items: allproducts.map((item, index) => ({
          item_name: item.product.name,
          item_id: item.product.id,
          is_active: item.product.is_active,
          price: item.product.price,
          previous_price: item.previous_price,
          price_difference: item.price_difference,
          prices_changed: item.price_difference > 0,
          index,
          discount: item.product.local_price ? item.product.local_price - item.product.price : 0,
          currency: currency,
          quantity: item.quantity,
          item_category: item.product.category_name,
          item_category2: item?.product.category_id,
        })),
      },
    });
  }