import { defineMessages } from 'react-intl';

export default defineMessages({
  labelAddToCartId: {
    id: 'labelAddToCartId',
    defaultMessage: 'Add to cart',
  },
  textDisclaimerPrices:{
    id: 'textDisclaimerPrices',
    defaultMessage: 'Keep in mind that prices may have varied. By continuing unavailable products will not be added to the cart.'
  },
  titleRepeatOrder: {
    id: 'titleRepeatOrder',
    defaultMessage: 'Repeat order',
  },
});
