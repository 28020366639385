import { useContext } from 'react';
import { Context } from 'context/Context';
import Image from 'next/image';
import { ContainerModal, ContainerAlert, TitleAlert, TitleText, CloseTab, ContainerButtons, TextDifference, ButtonMoreProducts, ButtonContinue } from './styled';

export default function ModalNoProductsvailable({ closeModal, actionContinue }) {
    const { country } = useContext(Context);
    const text = country === 1 ? 'En este momento no podés repetir el pedido porque los productos no se encuentran disponibles.' : 'En este momento no puedes repetir el pedido porque los productos no se encuentran disponibles.';
    return (
        <ContainerModal>
            <ContainerAlert>
                    <TitleText>
                        Productos no disponibles
                    </TitleText>
                    <TextDifference>
                        {text}
                    </TextDifference>
                <ContainerButtons>
                    <ButtonContinue onClick={closeModal}>
                        Entendido
                    </ButtonContinue>
                </ContainerButtons>
            </ContainerAlert>
        </ContainerModal>
    )
}