import { finalPrice } from './producthelpers'

export default function getTotal (lines) {
    let total = 0
    lines.forEach(line => {
        total = total + (finalPrice(line.product) * line.quantity)
    });
    return total
}

